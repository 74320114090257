import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import axios from 'axios';

import { getFee, payToContract, subscribeOnce } from './../../Web3Wrapper'
import { getEtherPrice, activateUser, getCountry, savePay } from './../../APIWrapper'
import { Card } from 'antd'
import LoadingButton from '../../shared/LoadingButton'
import { APIEndpoint } from "../../utils/APIendpoint"
import './Upgrade.css'

const Upgrade = (props) => {

  useEffect(() => {
    updatePrice();
    getCountryLocal();
  }, []);


  const [price, setPrice] = useState(0);
  const [fee, setFee] = useState(0);
  const [disable, setDisable] = useState(true);
  const [didPay, setDidPay] = useState(false);
  const [country, setCountry] = useState([]);
  const [selectCountry, setSelectCountry] = useState(-1);

  const paySubscription = (price) => {
    savePay(APIEndpoint, props.user.address, price, selectCountry, props.chainID)
    .then((res) => { 
      console.log(res);
    }).catch((err) => {
      console.log(err);
    });
    return new Promise((resolve, reject) => {
      subscribeOnce(props.contract, 'UserActivation')
        .then(event => window.location.reload())
        .catch(err => console.log(err));

      payToContract(props.contract, price)
        .then(res => {
          setDidPay(true);
          console.log("Resending petition to activate user...")
          setTimeout(() => activateUser(APIEndpoint, props.user.address, props.chainID)
            .then(res => console.log(res))
            .catch(err => console.log(err)), 30000)

          resolve(res)
        })
        .catch(err => reject(err));
    })
  }

  const disablePaymentButton = (value) => {
    setDisable(value);
  }

  const updatePrice = () => {
    Promise.all([
      getEtherPrice(props.coin),
      getFee(props.contract)
    ])
      .then(([price, fee]) => {
        let total = (1 / price) * (fee / 100)

        total = parseFloat(total.toFixed(5));

        setPrice(total);
        setFee(fee / 100);
        disablePaymentButton(false);
      })
      .catch(err => console.log(err));
  }

  const getCountryLocal = () => {
    Promise.all([
      getCountry(APIEndpoint, props.user.address),
    ])
      .then((res) => {
        const data = res[0];
        setCountry(data);
      })
      .catch(err => console.log(err));
  }

  let beforePaying = (
    <span>
      <p>Si estás en una PC, presiona “SUSCRIBIR” en el menú. Se abrirá tu cartera de Metamask con el monto
         preseleccionado en <strong>{props.coin}</strong> en la red <strong>{props.label}</strong> equivalente
         a los <strong>USD&nbsp;{fee}</strong> de la suscripción (usando el tipo de cambio actual), luego
         tan solo presiona “CONFIRM” en Metamask y tu pago será enviado a procesamiento.</p>

      <p>Si estás en el teléfono con Coinbase, aprieta las tres rallitas a mano izquierda arriba y te abrirá el menú. 
        Presiona “SUSCRIBIR” y tu billetera te pedirá el monto equivalente a los <strong>USD&nbsp;{fee}</strong> de
        la suscripción. Luego tan solo confirma el pago.</p>
      
      <p><strong>Aguarda unos 2 o 3 minutos </strong>mientras el pago se procesa y ya tendrás acceso a la plataforma</p>

      <p>Monto a pagar ({fee} USD): {price} {props.coin} </p>
      <div>
        <label>País:&nbsp;&nbsp;</label>
        <select value={ selectCountry } onChange={ (e) => { setSelectCountry(e.target.value) } }>
          <option key={ -1 } value={ -1 }>Selecciona tu país de residencia...</option>
          {
            country.map((item, i) => 
              <option key={ i } value={ item.Id }>{ item.Name }</option>
            )
          }
        </select>
      </div>
      <br/>
      <LoadingButton type="primary" disabled={disable || selectCountry === -1} onClick={() => paySubscription(price)}>
        SUSCRIBIR
      </LoadingButton>
    </span>
  );

  let afterPaying = (
    <span>
      <p>Gracias por tu subscripción, en unos minutos, te llegará un correo con la información para ingresar a tu
      plataforma.</p>

      <p>Para poder afiliar a otras personas, debes convertirte en guía Qori, para lo cual necesitas tener cierto
      conocimiento de criptomonedas. Si ya lo tienes, espera a que se confirme tu pago y presiona “TEST” en el menú
      a mano izquierda y realiza el examen. Una vez aprobado el mismo, deberás aceptar los términos y condiciones del
      guía Qori y podrás descargar tu manual del Guía Qori, que te ayudará en el proceso de inscripción de nuevos
      usuarios.</p>

      <p>En caso que no tengas aún el conocimiento, entra a la plataforma y realiza el curso de criptoeconomía.</p>
    </span>
  );

  if (props.dashboardStatus === 'upgrade') {
    return (
      <Card>
        {!didPay ? beforePaying : afterPaying}
      </Card>
    );
  }
  return null;
}

const mapStateToProps = state => {
  return {
    user: state.user,
    children: state.children,
    //
    dashboardStatus: state.dashBoardStatus,
    contract: state.contractAddress,
    coin :state.selectedChain.currency,
    label: state.selectedChain.label,
    chainID: state.chainID,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserChange: (user) => dispatch({ type: 'CHANGEUSER', value: user }),
    onChangeDashboard: (value) => dispatch({ type: 'CHANGEDASHBOARD', value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade);