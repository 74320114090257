import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import Modal from './Modal';
//import { getAllChains } from "../store/action";

const UnblockModal = (props) => {
  const dispatch = useDispatch();

  // const actualContract = useSelector(state => state.contractAddress);
  // const actualChain = useSelector(state => state.selectedChain);

  // useEffect(() => {
  //   dispatch(getAllChains())
  // }, []);

  const connectWallet = () => {
    if (window.ethereum) {
      try { 
          window.ethereum.request({ method: 'eth_requestAccounts' }).then(function(accounts) {
            console.log('Acceso concedido...');
          });
      } catch(e) {
          console.log(e);
          console.log('Acceso denegado...');
      }
    }
  }

  // const checkReducer = () => {
  //   console.log('actualChain: ', actualChain)
  //   console.log('actualContract: ', actualContract)
  // }

  return props.dashboardStatus === 'unblock' && (
  <Modal>
    <p>Por favor, ingresa a tu billetera Ethereum (Metamask/Coinbase/Status) y/o autoriza una de tus cuentas desde la ventana emergente una vez que presiones el botón de conectar billetera.</p>
    <Button type="primary"><p onClick={ connectWallet }>Conectar Wallet</p></Button>
    {/* <Button type="primary" onClick={checkReducer}>Check Reducer</Button> */}
  </Modal>
  );
};

const mapStateToProps = (state) => ({
  dashboardStatus: state.dashBoardStatus,
  //chainID: state.chainID,
});

export default connect(mapStateToProps)(UnblockModal);