import React, { Component } from 'react';
import { connect } from 'react-redux'

// User Options
import Profile from '../../components/Dashboard/Profile'
import Home from '../../components/Dashboard/Home'
import Test from './Test/Test'
import Affiliates from './Affiliates'
import Upgrade from './Upgrade'
import Locations from './Locations'

// Admin Options
import Operations from './Operations';
import History from '../History/History';
import Payments from '../Payments/Payments';
import Indicators from '../Indicators/Indicators';
import Modals from '../../Modals/Modals';

import { Layout } from 'antd';

const { Content } = Layout;

class Dashboard extends Component {
  render() {
    let { dashboardStatus, user } = { ...this.props };

    let adminOptions = () => {
      if (!user.admin) return null;

      if (dashboardStatus === 'operations') return (<Operations />)
      if (dashboardStatus === 'indicators') return (<Indicators />)
      if (dashboardStatus === 'history') return (<History />)
      if (dashboardStatus === 'payments') return (<Payments />)

      return null;
    }

    let userOptions = () => {
      if (dashboardStatus === 'home') return (<Home />)
      if (dashboardStatus === 'profile') return (<Profile />)
      if (dashboardStatus === 'test') return (<Test />)
      if (dashboardStatus === 'upgrade') return (<Upgrade />)
      if (dashboardStatus === 'affiliates') return (<Affiliates />)
      if (dashboardStatus === 'locations') return (<Locations />)

      return null;
    }

    return (
      <Content style={{ margin: '24px 16px 0' }}>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          {userOptions()}
          {adminOptions()}
          <Modals />
        </div>
      </Content>
    );
  }
}

const mapStateToProps = state => {
  //console.log("States del Dashboard:" + JSON.stringify(state));
  return {
    user: state.user,
    contract: state.contractAddress,
    dashboardStatus: state.dashBoardStatus,
  };
};

export default connect(mapStateToProps)(Dashboard);