import React from 'react';

import { Card } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const MissingEthers = (props) =>
  props.isWalletEmpty ? (
    <Card>
      <p><WarningOutlined /> No se encontraron ethers en el contrato</p>
    </Card>
  ) : props.children;

export default MissingEthers;