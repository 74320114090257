import { getClientAddress } from './Web3Wrapper'

console.log("API wrapper successfully initialized");

export const getUserDB = (endpoint) => {
  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => fetch(endpoint + 'users/' + address)
        .then(response => response.json()))
      .then(response => {
        let message = response.message;
        if (message.errno === "ECONNREFUSED") {
          resolve({ email: null })
        } else if (message.length === 0) {
          resolve({ email: null })
        } else resolve({ email: message[0].email })
      })
      .catch(err => reject(err));
  });
}

export const getCoordsDB = (endpoint) => {

  return new Promise((resolve, reject) => {

    getClientAddress()
      .then(address => fetch(endpoint + 'guides/' + address)
        .then(response => response.json()))
      .then(response => {
        let message = response.message;

        if (message.errno === "ECONNREFUSED") {
          resolve({ lat: null, lng: null })
        } else if (message.length === 0) {
          resolve({ lat: null, lng: null })
        } else resolve({ lat: Number(message[0].lat), lng: Number(message[0].lng) })
      })
      .catch(err => reject(err));
  });
}

export const registerUserDB = (endpoint, user, chainID) => {

  return new Promise((resolve, reject) => {

    fetch(endpoint + 'users', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({
        email: user.email,
        address: user.address,
        user_parent: user.user_parent,
        chain_id: chainID,
      })
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(err => reject(err))
  });
}

export const getAnyUserDB = (endpoint, address) => {

  return new Promise((resolve, reject) => {
    fetch(endpoint + 'users/' + address)
      .then(response => response.json())
      .then(response => {
        let message = response.message;
        if (message.errno === "ECONNREFUSED") {
          resolve({ email: null })
        } else if (message.length === 0) {
          resolve({ email: null })
        } else resolve({ email: message[0].email })
      })
      .catch(err => reject(err));
  });
}

export const requestNonce = (endpoint, signature) => {

  return new Promise((resolve, reject) => {

    getClientAddress()
      .then(address => fetch(endpoint + 'authenticate/' + address))
      .then(response => response.json())
      .then(response => resolve(response.message))
      .catch(err => reject(err));

  });
}

export const requestJWT = (endpoint, signature) => {

  return new Promise((resolve, reject) => {

    getClientAddress()
      .then(address => fetch(endpoint + 'authenticate', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          owner: address,
          sig: signature
        })
      })
        .then(response => response.json()))
      .then(jwt => {
        if (jwt.err) throw new Error(jwt.err);

        resolve(jwt)
      })
      .catch(err => reject(err));

  });
}
export const sendJWT = (endpoint, jwt) => {

  return new Promise((resolve, reject) => {

    getClientAddress()
      .then(address => fetch(endpoint + 'auth', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          owner: address,
          token: jwt.token
        })
      })
        .then(response => response.json()))
      .then(response => {
        if (response.error) {
          reject(new Error(response.error));
        } else {
          resolve(response)
        }
      })
      .catch(err => reject(err));

  });
}
export const getEtherPrice = (symbol) => {
  return new Promise((resolve, reject) => {
    fetch(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=USD`)
      .then(res => res.json())
      .then(res => resolve(res.USD))
      .catch(err => reject(err));
  });
}

export const getGuideMarkers = (endpoint) => {
  return new Promise((resolve, reject) => {
    fetch(endpoint + 'guides')
      .then(res => res.json())
      .then(res => resolve(res.message))
      .catch(err => reject(err));
    // resolve([
    //   { lat: -12.091425894596304, lng: -77.06860011681061, email: 'criptoguia1@cripto.com', address: '0x12412341231' },
    //   { lat: -12.090796445106788, lng: -77.06971591576081, email: 'criptoguia2@cripto.com', address: '0x12412341232' },
    //   { lat: -12.095106741810282, lng: -77.06968229479332, email: 'criptoguia3@cripto.com', address: '0x12412341233' },
    //   { lat: -12.093281359333657, lng: -77.07242887682457, email: 'criptoguia4@cripto.com', address: '0x12412341234' }
    // ])
  })
}

export const editCoords = (endpoint, address, latLng) => {
  let jwt = JSON.parse(localStorage.getItem('qoriJWT'));

  return new Promise((resolve, reject) => {
    fetch(endpoint + 'guides/' + address, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({
        lat: latLng.lat,
        lng: latLng.lng,
        token: jwt.token
      })
    })
      .then(res => res.json())
      .then(res => resolve(res.message))
      .catch(err => reject(err));
  })
}

export const editMail = (endpoint, address, email) => {
  let jwt = JSON.parse(localStorage.getItem('qoriJWT'));
  return new Promise((resolve, reject) => {
    fetch(endpoint + 'edit/' + address, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({
        email: email,
        token: jwt.token
      })
    })
      .then(res => res.json())
      .then(res => resolve(res.message))
      .catch(err => reject(err));
  })
}

export const activateUser = (endpoint, address, chainID) => {
  let jwt = JSON.parse(localStorage.getItem('qoriJWT'));
  return new Promise((resolve, reject) => {
    fetch(endpoint + 'activate/' + address, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({
        token: jwt.token,
        chain_id: chainID,
        /*price: price,
        selectCountry: selectCountry*/
      })
    })
      .then(res => res.json())
      .then(res => resolve(res.message))
      .catch(err => reject(err));
  })
}

export const getCountry = (endpoint, address) => {
  return new Promise((resolve, reject) => {
    fetch(endpoint + 'country', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET",
    })
      .then(res => res.json())
      .then(res => resolve(res.message))
      .catch(err => reject(err));
  })
}

export const savePay = (endpoint, address, price, selectCountry,chainID) => {
  let jwt = JSON.parse(localStorage.getItem('qoriJWT'));
  return new Promise((resolve, reject) => {
    fetch(endpoint + 'savePay/' + address, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({
        token: jwt.token,
        price: price,
        selectCountry: selectCountry,
        chain_id: chainID,
      })
    })
      .then(res => res.json())
      .then(res => resolve(res.message))
      .catch(err => reject(err));
  })
}