import Web3 from 'web3';
import QoriManager from './contracts/QoriManager';
// import OwnedUpgradeabilityProxy from './OwnedUpgradeabilityProxy';
import OwnedUpgradeabilityProxy from './contracts/AdminUpgradeabilityProxy';

const web3 = new Web3(window.ethereum);

console.log("web3 wrapper successfully initialized");

export const getClientAddress = () => {
  return new Promise((resolve, reject) => {
    web3.eth.net.isListening()
      .then(_ => web3.eth.getAccounts())
      .then(addresses => {
        if (addresses.length === 0) {
          throw new Error("Metamask wallet is empty!")
        }
        return addresses[0];
      })
      .then(clientAddress => resolve(clientAddress))
      .catch(err => reject(err));
  });
}

export const getClientAddresses = () => {
  return new Promise((resolve, reject) => {
    web3.eth.net.isListening()
      .then(_ => web3.eth.getAccounts())
      .then(addresses => resolve(addresses))
      .catch(err => reject(err));
  });
}

getClientAddress()
  .then(address => {
    let main_address = address;
    let checker = setInterval(() => {
      getClientAddress()
        .then(address => {
          if (address !== main_address) {
            clearInterval(checker);
            window.location.reload();
          }
        })
        .catch(err => {
          console.log(err);
          clearInterval(checker);
          window.location.reload();
        })
    }, 500)
  })
  .catch(err => {
    console.log(err);
  });

getClientAddresses()
  .then(addresses => {
    let len = addresses.length;
    if (len !== 0) return;
    let checker = setInterval(() => {
      getClientAddresses()
        .then(addresses => {
          if (addresses.length !== 0) {
            clearInterval(checker);
            window.location.reload();
          }
        })
        .catch(err => console.log(err))
    }, 500)
  })
  .catch(err => console.log(err));

export const sendTx = (contract, method, values, proxy = false) => {
  let abi = proxy ? OwnedUpgradeabilityProxy.abi : QoriManager.abi;
  let qorimanager = new web3.eth.Contract(abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager
        .methods[method](...values)
        .send({ from: address }))
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const callTx = (contract, method, values, proxy = false) => {
  let abi = proxy ? OwnedUpgradeabilityProxy.abi : QoriManager.abi;
  let qorimanager = new web3.eth.Contract(abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager
        .methods[method](...values)
        .call({ from: address }))
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const getAllUsers = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address =>
        qorimanager.methods
          .getAllUsers()
          .call({ from: address })
      )
      .then(users => resolve(users))
      .catch(err => reject(err));
  });
}

export const getUser = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .users(address)
        .call({ from: address })
      )
      .then(user => resolve(user))
      .catch(err => reject(err));
  });
}

export const getAnyUser = (contract, address) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    qorimanager.methods
      .users(address)
      .call({ from: address })
      .then(user => resolve(user))
      .catch(err => reject(err));
  });
}

export const signMessage = (nonce) => {
  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => {
        web3.currentProvider
          .sendAsync({
            id: 1,
            method: 'personal_sign',
            // params: [address, web3.utils.toHex(nonce)]
            params: [web3.utils.toHex(nonce), address]
          }, (err, response) => {

            if (err) reject(err);
            else resolve(response.result);

          });
      })
      .catch(err => reject(err));
  });
}

export const autoGuide = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .autoGuide()
        .send({ from: address }))
      .then(user => resolve(user))
      .catch(err => reject(err));
  });
}

export const getChildren = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .getChildren(address)
        .call({ from: address }))
      .then(user => resolve(user))
      .catch(err => reject(err));
  });
}

export const getAnyChildren = (contract, address) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    qorimanager.methods
      .getChildren(address)
      .call({ from: address })
      .then(user => resolve(user))
      .catch(err => reject(err));
  });
}

export const payToContract = (contract, price) => {
  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => web3.eth
        .sendTransaction({
          from: address,
          to: contract,
          value: web3.utils.toWei(String(price)),
          gas: 60000
        })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const isOwner = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => Promise.all([
        qorimanager.methods
          .proxyOwner()
          .call({ from: address }),
        address
      ])
      )
      .then(([owner, address]) => {

        if (owner === address) resolve(true)
        else resolve(false)

      })
      .catch(err => reject(err));
  });
}

export const getFee = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .FEE()
        .call({ from: address })
      )
      .then(fee => resolve(fee))
      .catch(err => reject(err));
  });
}

export const getPercentages = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .getPercentages()
        .call({ from: address })
      )
      .then(percentage => resolve(percentage))
      .catch(err => reject(err));
  });
}

export const changeFee = (contract, value) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .changeFee(value)
        .send({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const changePercentages = (contract, value, index) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .changePercentages(value, index)
        .send({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const changeUserEmail = (contract, email) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .changeUserEmail(email)
        .send({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const getPastEvents = (contract, event) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  // let latestBlock = 4851996;
  let latestBlock = 0;

  return new Promise((resolve, reject) => {
    qorimanager.getPastEvents(event, { fromBlock: latestBlock })
      .then(e => resolve(e))
      .catch(err => reject(err));
  });
}

export const subscribeOnce = (contract, event) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract, {
    /*defaultGas: 60000*/
  });

  // let latestBlock = 4851996;
  let latestBlock = 0;
  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => {
        qorimanager.once(event, {

          // Using an array means OR: e.g. 20 or 23
          filter: { _user: address },
          // 'latest'
          fromBlock: latestBlock
        }, (error, event) => {
          if (error) reject(error)
          else resolve(event)
        })
      })
  });
}

export const withdrawFunds = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .withdrawFunds(address)
        .send({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const withdrawSomeFunds = (contract, amount) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .withdrawSomeFunds(address, amount)
        .send({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const withdrawFundsToUser = (contract, receiver, amount) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .withdrawFundsToUser(receiver, amount)
        .send({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const activateUser = (contract, user, email, parent, fee) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .activateUser(user, email, parent, fee)
        .send({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const withdrawAllFunds = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .withdrawAllFunds(address)
        .send({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const getContractBalance = (contract) => {
  let qorimanager = new web3.eth.Contract(QoriManager.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .CONTRACT_BALANCE()
        .call({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const upgradeTo = (contract, implementation) => {
  let qorimanager = new web3.eth.Contract(OwnedUpgradeabilityProxy.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .upgradeTo(implementation)
        .send({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const implementation = (contract) => {
  let qorimanager = new web3.eth.Contract(OwnedUpgradeabilityProxy.abi, contract);

  return new Promise((resolve, reject) => {
    getClientAddress()
      .then(address => qorimanager.methods
        .implementation()
        .call({ from: address })
      )
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export const fromWei = (value) => {
  return web3.utils.fromWei(value);
}

export const toWei = (value) => {
  return web3.utils.toWei(value);
}

export const getBalance = (address) => {
  return web3.eth.getBalance(address);
}

export const getBlock = (block) => {
  return web3.eth.getBlock(block);
}

export const utils = web3.utils;

export default web3;