import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import { Radio, Button } from 'antd';
import Modal from './Modal';
import { connectWallet } from "../utils/connectWallet";

const ChangeChainModal = (props) => {
  // reducer state
  const allChains = useSelector(state => state.allChains);
  const actualChain = useSelector(state => state.selectedChain);

  // local state
  const [chainID, setChainID] = useState(props.chainID);
  const [chain, setChain] = useState(actualChain);

  const settingChain = async() => {
    if (!Array.isArray(chain)) return alert('Debes seleccionar una red antes')
    window.localStorage.setItem('CHAINID_SELECTED', JSON.stringify(chainID));
    if (await connectWallet(chain[0])) {
      window.location.reload()
    }
  }

  const onChangeChainID = async (e) => {
    let current = allChains.filter((c)=> c.value === e.target.value)
    setChain(current)
    setChainID(e.target.value);
    console.log('Seteando estado local')
  };

  return props.dashboardStatus === 'changeChain' && (
    <Modal>
      <p>Por favor selecciona la red a la que te quieres conectar.</p>
      <p>Lista de redes disponibles:</p>
          <Radio.Group
            options={allChains}
            onChange={onChangeChainID}
            optionType="button"
            buttonStyle="solid"
            style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px" }}
          />
        <Button id="connectBtn" type="primary"><p onClick = { settingChain }>Conectar Wallet</p></Button>
    </Modal>
  )
};

const mapStateToProps = (state) => ({
  dashboardStatus: state.dashBoardStatus,
});

export default connect(mapStateToProps)(ChangeChainModal);