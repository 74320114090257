// PAGE AFFILIATES
// This page shows the link that must be shared in order to become someone's parent
// This page shows the tree of affiliates of a Qori guide
// It does not take into account users registered on db but not yet active on the blockchain

import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'

import { copyToClipboard2 } from '../../UtilsWrapper'
import { getAnyChildren } from '../../Web3Wrapper';
import { getAnyUserDB as fetchUser } from '../../APIWrapper';
import { APIEndpoint } from "../../utils/APIendpoint";

import ExtendableAddress from '../../shared/ExtendableAddress';

import { Table, Button, Card, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './Affiliates.css'


const columns = [{
  title: 'Correo',
  dataIndex: 'email',
  key: 'email',
  render: text => text,
},
{
  title: 'Dirección ETH',
  dataIndex: 'address',
  key: 'address',
  render: text => <ExtendableAddress maxLen={20}>{text}</ExtendableAddress>,
  responsive: ['md'],
},
{
  title: 'Detalles',
  dataIndex: 'details',
  key: 'details',
  render: () => <Button type="primary" disabled>Ver Detalle</Button>,
  responsive: ['md'],
}];

const getChildrenFromDBAndBCRecursively = (contract, APIEndpoint) => (parent) => {
  return Promise.all([getAnyChildren(contract, parent), fetchUser(APIEndpoint, parent)])
    .then(([children, user]) => {
      if (children.length !== 0) {
        return Promise.all(children.map(child => getChildrenFromDBAndBCRecursively(contract, APIEndpoint)(child)))
          .then(res => ({ key: parent, address: parent, children: res, ...user }))
      } else {
        return ({ key: parent, address: parent, ...user });
      }
    })
}

const handleCopy = (url) => {
  copyToClipboard2(url);
  message.success('Dirección de afiliación copiada');
}

const Affiliates = (props) => {

  const [affiliatesState, setAffiliatesState] = useState({ children: [], loading: true })
  const url = 'https://' + window.location.hostname + '/' + props.user.address;

  // Fetch children (or affiliates) recursively at start 
  // Included unmounted component guard https://stackoverflow.com/questions/56442582/react-hooks-cant-perform-a-react-state-update-on-an-unmounted-component
  useEffect(() => {
    // getBalance()
    let _isMounted = true;
    getChildrenFromDBAndBCRecursively(props.contract, APIEndpoint)(props.user.address)
      .then(user => {
        if (_isMounted)
          setAffiliatesState({ children: user.children, loading: false })
      })
      .catch(err => console.log(err))   
    return () => {
      _isMounted = false;
    };
  }, []);

  return props.dashboardStatus === 'affiliates' && (
    <div> 
      <Card title="Link de afiliación">
        <p>Esta es tu dirección para afiliar usuarios (haz click para copiar):</p>
        <Button
          type="dashed"
          onClick={() => handleCopy(url)}>
          <p className="break-all">
            <CopyOutlined /> {url}
          </p>
        </Button>
      </Card>
      <br />
      <Card title="Afiliados">
        <Table columns={columns} dataSource={affiliatesState.children} />
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  children: state.children,
  //
  dashboardStatus: state.dashBoardStatus,
  contract: state.contractAddress,
  //
});

const mapDispatchToProps = (dispatch) => ({
  onChildrenChange: (children) => dispatch({ type: 'CHANGECHILDREN', value: children }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Affiliates);