import React, { Component } from 'react';
import { connect } from 'react-redux';

import { signMessage } from './../Web3Wrapper'
import { requestJWT, requestNonce, sendJWT } from './../APIWrapper';
import { APIEndpoint } from '../utils/APIendpoint';

import Modal from './Modal';
import LoadingButton from '../shared/LoadingButton';

class LoginModal extends Component {
  grantAccess = () => {
    return new Promise((resolve, reject) => {
      requestNonce(APIEndpoint)
        .then(nonce => signMessage(nonce))
        .then(signature => requestJWT(APIEndpoint, signature))
        .then(jwt => {
          localStorage.setItem('qoriJWT', JSON.stringify(jwt)); //make 'qoriJWT' a constant
          this.props.onChangeDashboard('home');
          this.props.onToggleHeader(true);
          this.props.onToggleSidebar(true);
          resolve()
        })
        .catch(err => reject(err))
    })
  }

  signIn = () => {
    let jwt = JSON.parse(localStorage.getItem('qoriJWT')); //make 'qoriJWT' a constant
    return new Promise((resolve, reject) => {
      if (jwt) {
        sendJWT(APIEndpoint, jwt)
          .then(isValid => {
            this.props.onChangeDashboard('home');
            this.props.onToggleHeader(true);
            this.props.onToggleSidebar(true);
            resolve()
          })
          .catch(err => {
            console.log(err)
            this.grantAccess()
              .then(resolve)
              .catch(reject)
          });
      } else this.grantAccess()
        .then(resolve)
        .catch(reject);
    })
  }

  render() {
    return this.props.dashboardStatus === 'login' && (
      <Modal>
        <p>A continuación, deberás firmar electrónicamente un mensaje con tu billetera Ethereum para demostrar que esta es tu cuenta.</p>
        <p>Aprieta el botón azul &quot;Firmar&quot; y si usas metamask aprieta “SIGN” o “FIRMAR” una vez que aparezca la ventana emergente.</p>
        <p>En caso de que uses Coinbase, Status u otro, aprieta el botón “Firmar” y seguidamente “AGREE”.</p>
        <LoadingButton type="primary" onClick={this.signIn}> Firmar </LoadingButton>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  show: state.showLoginModal,
  dashboardStatus: state.dashBoardStatus,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeDashboard: (value) => dispatch({ type: 'CHANGEDASHBOARD', value: value }),
  //
  onToggleHeader: (value) => dispatch({ type: 'TOGGLEHEADER', value: value }),
  onToggleSidebar: (user) => dispatch({ type: 'TOGGLESIDEBAR', value: user }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);