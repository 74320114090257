import React from 'react';

import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { copyToClipboard } from './../../UtilsWrapper';
import { message } from 'antd';

const LocationsMapComponent = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat: props.lat, lng: props.lng }}
  >
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >
      {props.markers.map(marker => (
        <MarkerWithLabel
          key={marker.address}
          position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
          labelAnchor={new window.google.maps.Point(0, 0)}
          labelStyle={{ backgroundColor: "yellow", fontSize: "10px", padding: "8px" }}
          onClick={() => {
            message.success('Copied');
            copyToClipboard(marker.email)
          }}
        >
          <div>
            <p>{marker.email}</p>
            <p>{marker.address}</p>
          </div>
        </MarkerWithLabel  >
      ))}
    </MarkerClusterer>
  </GoogleMap>
));

export default LocationsMapComponent;