import React, { Component } from 'react';

import { Button, Popconfirm, message } from 'antd';
import { CopyOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { copyToClipboard } from './../UtilsWrapper';

class ExtendableAddress extends Component {
  state = {
    address: this.props.children,
    visible: false,
  }

  hide = () => {
    this.setState({ visible: false });
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  truncateWithEllipses = (text, max) => text.substr(0, max - 1) + (text.length > max ? '...' : '');

  confirm = (e) => {
    copyToClipboard(this.state.address)
    message.success('Copied');
  }

  render() {
    let maxLen = this.props.maxLen || 7;
    return (
      <Popconfirm
        icon={null}
        placement="topLeft"
        title={this.state.address}
        onConfirm={this.confirm}
        okText={<CopyOutlined />}
        cancelText={<CloseCircleOutlined />}
      >
        <Button type="dashed">{this.truncateWithEllipses(this.props.children.toString(), maxLen)}</Button>
      </Popconfirm>
    );
  }
}

export default ExtendableAddress;