import React, { Component } from 'react';
import { connect } from 'react-redux'

import EventHistory from './EventHistory';

class History extends Component {
	
	state = {
		eventList: [
      "FeeChange",
      "PercentagesChange",
			"FundsWithdrawal",
		]
	}
  
  render() {
    if (this.props.dashboardStatus === 'history') {
			let events = this.state.eventList.map((eventString, index, array) => (
        <div key={index}>
          <EventHistory 
            key = {eventString}
						contract = {this.props.contract}
						event = {eventString} 
          />
          {array.length - 1 !== index ? <br/> : null}
        </div>
			))
      return (
        <div className="card">
					{events}
        </div>
      );
    }
		
		return null;
  }
}

const mapStateToProps = (state) => {
	return {
		dashboardStatus: state.dashBoardStatus,
		contract: state.contractAddress,
	};
};

export default connect(mapStateToProps)(History);