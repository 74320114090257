import React from 'react';

// @dev Tentative new name: GenericContractForm

import QoriManager from "./../contracts/QoriManager";
import OwnedUpgradeabilityProxy from "./../contracts/AdminUpgradeabilityProxy";

import { Row } from 'antd';
import BasicForm from './BasicForm';
import BasicOutput from './BasicOutput';

const contractFunctions = [
  { name: 'changePercentages', show: 'getPercentages' },
  { name: 'changePercentagesCap', show: 'PERCENTAGES_CAP' },
  { name: 'changeFee', show: 'FEE' },
  { name: 'changeLimit', show: 'LIMIT' },
  { name: 'upgradeUserToGuide' },
  { name: 'upgradeTo', show: 'implementation' },
  { name: 'initContract', show: 'isInitiated' },
  { name: 'changeAdmin', show: 'admin' }
]

const UniversalInput = () => {
  let forms = (abi, proxy = false) => abi
    .filter(f => contractFunctions.map(o => o.name).includes(f.name))
    .map((f, index) => {
      let [name, show] = contractFunctions.reduce((acc, e) => { if (e.name === f.name) acc = [e.name, e.show]; return acc }, []);
      return (
        <Row key={index} gutter={16} style={{ paddingBottom: '30px', display: "flex", flexWrap: "wrap" }}>
          {name && <BasicForm title={f.name} inputs={f.inputs} proxy={proxy} />}
          {show && <BasicOutput title={show} proxy={proxy} />}
        </Row>
      )
    })

  let managerForms = forms(QoriManager.abi);
  let proxyForms = forms(OwnedUpgradeabilityProxy.abi, true);

  return (
    <div style={{ background: '#ECECEC', padding: '10px' }}>
      {managerForms}
      {proxyForms}
    </div>
  );
}

export default UniversalInput;