import React, { Component } from 'react';
import { connect } from 'react-redux'

import { getCoordsDB, editCoords } from '../../APIWrapper';
import GuideMapComponent from './GuideMapComponent';
import { APIEndpoint } from '../../utils/APIendpoint';

import './Maps.css';
import { Button, Spin, Card, message } from 'antd';

class GuideMap extends Component {
  state = {
    coordinatesLoaded: false,
    lat: 0,
    lng: 0,
    marker: {
      lat: 0,
      lng: 0
    }
  }

  componentDidMount = () => {
    getCoordsDB(APIEndpoint)
      .then((latLngDB) => {
        const { lat, lng } = { ...latLngDB }
        // Ubicación por default, cerca a la Plaza Bolognesi (podría cambiar dependiendo del país del guía)
        const defaultLatitude = -12.06;
        const defaultLongitude = -77.04;
        this.setState({
          coordinatesLoaded: true,
          lat: lat || defaultLatitude,
          lng: lng || defaultLongitude,
          marker: {
            lat: lat || defaultLatitude,
            lng: lng || defaultLongitude
          }
        })
      })
      .catch(err => console.log(err));
  }

  onMarkerChange = (lat, lng) => {
    this.setState({
      marker: {
        lat: lat,
        lng: lng
      }
    })
  }

  onSave = () => {
    let { lat, lng } = { ...this.state }
    editCoords(APIEndpoint, this.props.user.address, this.state.marker)
      .then(res => {
        console.log(res);
        message.success('Guardado');
        this.props.onUserChange({ lat: lat, lng: lng })
      })
      .catch(err => console.log(err))
  }

  render() {
    const { coordinatesLoaded, lat, lng, marker } = this.state;
    const { onMarkerChange, onSave } = this;

    return coordinatesLoaded ? (
      <div>
        <GuideMapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCNBeqrIDyadJg9l9Fj_GyzprfMfq7WKr0&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          lat={lat}
          lng={lng}
          marker={marker}
          onMarkerChange={onMarkerChange}
        />
        <br />
        <Button type="primary" onClick={onSave}>Guardar</Button>
      </div>
    ) : (
        <Card>
          Por favor, comparte tu ubicacion, esta información no es guardada en ningún servidor de qori
          <br /><br />
          <Spin />
        </Card>
      )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserChange: (user) => dispatch({ type: 'CHANGEUSER', value: user })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuideMap);