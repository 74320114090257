import React from 'react';
import { connect } from 'react-redux'

import { sendTx } from '../Web3Wrapper';

import { Input, Form, Button, Card } from 'antd';
import withForm from '../shared/HOC/withForm';

class BasicForm extends React.Component {
  state = {
    loading: false,
  }

  quickFormatter = (string) => string.charAt(1).toUpperCase() + string.slice(2);

  sendTransaction = (valuesObj) => {
    const { contract, title, proxy } = this.props;
    const values = Object.keys(valuesObj).map(key => valuesObj[key]);
    this.setState({ loading: true }, () => {
      sendTx(contract, title, values, proxy)
        .then(res => console.log(res))
        .catch(err => {
          console.log(err);
          this.setState({ loading: false })
        });
    });
  }

  handleSubmit = () => {
    this.props.form.validateFields()
      .then(values => this.sendTransaction(values))
      .catch(err => console.log(err))
  }

  render() {
    const { props } = this;
    let inputs = props.inputs.map((i, index) => (
      <Form.Item
        key={index}
        name={[i.name]}
        rules={[{ required: true, message: 'Please input your ' + this.quickFormatter(i.name) + '!' }]}
      >
        <Input
          addonBefore={this.quickFormatter(i.name)}
          placeholder={i.type}
        />
      </Form.Item>
    ))
    return (
      <Card title={props.title} style={{ minWidth: "200px", flex: "1", marginLeft: "5px", marginRight: "5px" }}>
        <Form onFinish={this.handleSubmit} form={this.props.form} className="login-form">
          {inputs}
          <Form.Item>
            <Button
              loading={this.state.loading}
              className="login-form-button"
              type="primary"
              htmlType="submit"
              style={{ float: "right" }}
            >
              ACTUALIZAR
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  contract: state.contractAddress,
});

// export default connect(mapStateToProps)(Form.create({ name: 'normal_login' })(BasicForm));
export default connect(mapStateToProps)(withForm(BasicForm));