export const trace = x => {
  //console.log(x);
  return x;
}

export const traceTag = tag => x => {
  //console.log(tag, x);
  return x;
}

export const copyToClipboard = (text) => {
  let textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  // textArea.focus();
  textArea.select();

  try {
    let successful = document.execCommand('copy');
    let msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export const copyToClipboard2 = (str) => {
  const el = document.createElement('textarea');
  // var oldContentEditable = el.contentEditable,
  //   oldReadOnly = el.readOnly,
  var range = document.createRange();
  el.value = str;
  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);

  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();

  var s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);
  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

export const getCoords = () => {
  const options = {
    timeout: 10000
  };

  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          console.log('latitude', position.coords.latitude,
            'longitude', position.coords.longitude);
          resolve([position.coords.latitude, position.coords.longitude])
        },
        error_message => {
          console.error('An error has occured while retrieving location', error_message)
          reject(error_message)
        }, options)
    } else {
      console.log('geolocation is not enabled on this browser')
      reject(new Error('geolocation is not enabled on this browser'));
    }
  })
}

export const convertToDate = (UNIX_timestamp) => {
  let a = new Date(UNIX_timestamp * 1000);
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let year = a.getFullYear();
  let month = months[a.getMonth()];
  let date = a.getDate();
  let hour = a.getHours();
  let min = a.getMinutes();
  let sec = a.getSeconds();
  let time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
}
