import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import { Button, Radio } from 'antd';
import { connectWallet } from "../utils/connectWallet";
import Modal from './Modal';

const ErrorModal = (props) => {

  // reducer state
  const allChains = useSelector(state => state.allChains);
  const actualChain = useSelector(state => state.selectedChain);

  // local state
  const [chain, setChain] = useState(actualChain);
  
  const settingChain = async() => {
    if (!Array.isArray(chain)) return alert('Debes seleccionar una red antes')
    window.localStorage.setItem('CHAINID_SELECTED', JSON.stringify(chain[0].value));
    if (await connectWallet(chain[0])) {
      window.location.reload();
    }
  }

  const onChangeChainID = async (e) => {
    const current = allChains.filter((c)=> c.value === e.target.value);
    setChain(current);
    console.log("onChangeChainID = async (e) => " + JSON.stringify(chain));
  };

  return props.dashboardStatus === 'error' && (
    <Modal>
      <p>Algo ha ido mal. Probablemente no estas conectado a la red correcta.</p>
      <p>Si este mensaje sale durante el registro, es probable que el padre no esté autorizado a afiliar usuarios.</p>
      <p>Por favor selecciona la red a la que te quieres conectar de la lista de redes disponibles:</p>
      <Radio.Group
            options={allChains}
            onChange={onChangeChainID}
            optionType="button"
            buttonStyle="solid"
            style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px" }}
      />
      <Button id="connectBtn" type="primary"><p onClick = { settingChain }>Conectar Wallet</p></Button>
    </Modal>
  )
};

const mapStateToProps = (state) => {
  //console.log("States del ErrorModal: " + JSON.stringify(state));
  return {
    dashboardStatus: state.dashBoardStatus,
    chainID: state.chainID,
    contractAddress: state.contractAddress,
  }
}

export default connect(mapStateToProps)(ErrorModal);