import React from 'react';

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const GuideMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: props.lat, lng: props.lng }}
    onClick={(event) => props.onMarkerChange(event.latLng.lat(), event.latLng.lng())}
  >
    <Marker 
      position={{ lat: props.marker.lat, lng: props.marker.lng }}
    />
  </GoogleMap>
))

export default GuideMapComponent;