import React from 'react';
import { connect } from 'react-redux'

import { Layout, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const Header = (props) => {
  const getStatusString = (status) => {
    switch (Number(status)) {
      case 0:
        return 'No suscrito';
      case 2:
        return 'Activo';
      case 3:
        return 'Inactivo';
      default:
        return '-';
    }
  }

  const getItem = (label, key, icon, children, type, disabled) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      disabled,
    };
  }

  const itemsMenu = [
    getItem(props.user.address, 'userAddress', null,[],'groups'),
    getItem('Red: ' + props.selectedChain.label, 'sg1', null, [
      getItem('Cambiar Red...', 'changeChain', null)
    ])
    ,
    getItem('','manejarPerfil', <UserOutlined />, [
      getItem('Status: ' + getStatusString(props.user.status), 'StatusGroup', null, [
        getItem('Ver perfil...', 'profile', null)
      ], 'group')
    ])
  ];

  const onClick = (e) => {
    console.log('click ', e);
    props.onChangeDashboard(e.key);
  };

  return props.show === true && (
    <Layout.Header style={{ background: '#00213D', padding: 0, textAlign: 'right' }} >
      <Menu
        onClick={onClick}
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={['0']}
        style={{ lineHeight: '64px', display: "flex", justifyContent: "flex-end" }}
        items = {itemsMenu}
      />
    </Layout.Header>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  show: state.showHeader,
  selectedChain: state.selectedChain,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeDashboard: (value) => dispatch({ type: 'CHANGEDASHBOARD', value: value }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
