import React, { Component } from 'react';
import { connect } from 'react-redux'

import { callTx } from '../Web3Wrapper';

import { Card } from 'antd';

class BasicOutput extends Component {
  state = {
    loading: true,
    content: '',
  }

  componentDidMount = () => {
    callTx(this.props.contract, this.props.title, [], this.props.proxy)
      .then(res => {
        this.setState({ loading: false, content: res.toString() });
      })
      .catch(err => console.log(err))
  }

  render() {
    const { props } = this;
    return (
      <Card title={props.title} loading={this.state.loading} style={{ minWidth: "200px", flex: "1", marginLeft: "5px", marginRight: "5px" }}>
        <p style={{ wordBreak: "break-all" }}>
          {this.state.content}

        </p>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  contract: state.contractAddress,
});

export default connect(mapStateToProps)(BasicOutput);