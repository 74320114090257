import React from 'react';

import MediaQuery from 'react-responsive';
import { Card } from 'antd';
import Aux from './Auxiliar';

const styles = {
  card: {
    textAlign: "center",
    width: "50%",
    left: "25%",
  },
  div: {
    textAlign: "center",
  },
  span: {
    display: "inline-block",
  }
}

const cardWithMediaQuery = (WrappedComponent) => {
  return (props) => (
    <Aux>
      {/* Smarthphone Screen */}
      <MediaQuery query="(max-width: 500px)">

        <div style={styles.div}>
          <span style={styles.span}>
            {props.title}
            <WrappedComponent {...props} />
          </span>
        </div>

      </MediaQuery>
      {/* Normal Screen */}
      <MediaQuery query="(min-width: 500px)">

        <Card style={styles.card} title={props.title}>
          <span style={styles.span}>
            <WrappedComponent {...props} />
          </span>
        </Card>

      </MediaQuery>
    </Aux>
  )
}

export default cardWithMediaQuery;