import React from 'react';

import Aux from './../shared/HOC/Auxiliar'
import cardWithMediaQuery from './../shared/HOC/cardWithMediaQuery'

const Modal = (props) => (
  <Aux>
    {props.children}
  </Aux>
);

export default cardWithMediaQuery(Modal);