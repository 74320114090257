import React from 'react';
import { connect } from 'react-redux'

import UniversalInput from '../../contractForms/UniversalInput';

const Operations = (props) => props.dashboardStatus === 'operations' && (
  <UniversalInput />
);

const mapStateToProps = (state) => ({
  dashboardStatus: state.dashBoardStatus,
});

export default connect(mapStateToProps)(Operations);