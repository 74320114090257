import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Card } from 'antd';

class Home extends Component {
  render() {
    let status = Number(this.props.user.status);
    let guide = this.props.user.guide
    if ( (this.props.dashboardStatus === 'home') && (status === 0 || status === 3) ) {
      return (
        <Card>
          <h1>Bienvenido a Qori</h1>
          <h2>La Revolución del Conocimiento</h2>
          <p>Estás a sólo un paso de descubrir los secretos de este apasionante mundo de las criptomonedas 
          y el blockchain, donde aprenderás cómo utilizarlas y múltiples formas de generar ingresos. 
          Tan solo presiona el menú "SUSCRIBIRSE" y podrás hacer tu primera transacción con criptomonedas y
          así acceder a tu plataforma.</p>
        </Card>
      );
    }
    if (this.props.dashboardStatus === 'home' && (status === 2 && guide === false)) {
      return (
        <Card>
          <h1>Bienvenido, ¿listo para convertirte en guía Qori?</h1>
          <h2>Test para convertirte en Guía Qori</h2>
          <p>Para poder afiliar a otras personas, debes convertirte en guía Qori, para lo cual necesitas tener cierto
          conocimiento de criptomonedas. Si ya lo tienes, presiona “TEST” en el menú
          a mano izquierda y realiza el examen. Una vez aprobado el mismo, deberás aceptar los términos y condiciones del
          guía Qori y podrás descargar tu manual del Guía Qori, que te ayudará en el proceso de inscripción de nuevos
          usuarios.</p>

          <p>En caso que no tengas aún el conocimiento, entra a la plataforma y realiza el curso de criptoeconomía.</p>

          <p>Si ya aprobaste el Test puedes refrescar la página para reconocerte como un nuevo Guía Qori.</p>
        </Card>
      );
    }
    if (this.props.dashboardStatus === 'home' && (status === 2 && guide === true)) {
      return (
        <Card>
          <h1>Bienvenido, ¡Ya eres un guía Qori!</h1>
          <h2>Listos para afiliar y ganar</h2>
          <p>Ya puedes acceder a tu dirección para afiliar usuarios a la plataforma y ganar por cada afiliación,
          puedes ganar hasta tres niveles de afiliados. Accede a tu dirección para afiliar presionando "AFILIADOS" desde
          el menú. También puedes ver a todas las personas que has afiliado y han pagado su suscripción</p>
          <span> 
            <p>Por favor lee con detenimiento los siguientes Términos y Condiciones:</p>
            <p><a href="https://qori.io/Guia%20Qori/TyC%20Generales%20Qori%2019-05-07.pdf" target="_blank" rel="noopener noreferrer">Link a los
              términos y condiciones generales de usuarios</a></p>
            <p>Descarga aquí tu manual del Guía Qori (Al descargar tu manual, automáticamente estás aceptando los
              Términos y Condiciones del Guía Qori):</p>
            <p><a href="https://qori.io/Guia%20Qori/Manual%20del%20Guia%20Qori.pdf" target="_blank" rel="noopener noreferrer">Link al manual
              del Guía Qori</a></p>
            <p><a href="https://qori.io/Guia%20Qori/TyC%20Gui%CC%81as%20Qori%2019-05-07.pdf" target="_blank" rel="noopener noreferrer">Link a los
              términos y condiciones del Guía Qori</a></p>
          </span>
        </Card>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardStatus: state.dashBoardStatus,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Home);