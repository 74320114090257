import React from 'react';
import { connect } from 'react-redux';

import logo from './../logo.png'
import { Spin } from 'antd';

const styles = {
  splashScreen: {
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundColor: "#00213D",
    top: 0,
    left: 0,
  },
  splashContent: {
    position: "fixed",
    transform: "translateX(-50%)",
    left: "50%",
    top: "40%",
  },
  splashSpinner: {
    position: "fixed",
    transform: "translateX(-50%)",
    left: "50%",
  },
}

const SplashScreen = (props) => props.dashboardStatus === 'splash' && (
  <div style={styles.splashScreen}>
    <span style={styles.splashContent}>
      <img alt="qori-logo" src={logo} />
      <br />
      <Spin style={styles.splashSpinner} />
    </span>
  </div>
);

const mapStateToProps = (state) => ({
  dashboardStatus: state.dashBoardStatus,
});

export default connect(mapStateToProps)(SplashScreen);