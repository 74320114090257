import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Table, Tag } from 'antd';

import { getAllUsers, getAnyUser, getAnyChildren } from '../../Web3Wrapper';

import ExtendableAddress from '../../shared/ExtendableAddress'
// import { useReducer } from 'react';


const columns = [
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: text => <ExtendableAddress maxLen={22}>{text}</ExtendableAddress>,
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Parent',
    dataIndex: 'parent',
    key: 'parent',
    render: text => <ExtendableAddress maxLen={22}>{text}</ExtendableAddress>,
  },
  {
    title: 'Hijos',
    dataIndex: 'hijos',
    key: 'hijos',
    render: hijos => (
      <span>
        <Tag >{hijos ? hijos : '-'}</Tag>
      </span>
    ),
  },
  {
    title: 'Status',
    key: 'tags',
    dataIndex: 'tags',
    render: tags => (
      <span>
        <Tag
          color={tags[0] === '2' ? 'green' : 'volcano'}
        >
          {tags[0] === '2' ? 'ACTIVO' : 'INACTIVO'}
        </Tag>
        {tags[1] ? <Tag color='geekblue'>GUIA</Tag> : null}
      </span>
    ),
  },
];

class Indicators extends Component {
  state = {
    dataSource: null,
    loading: true,
  }

  _isMounted = false;

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    const { contract } = this.props;
    const formatUser = address => Promise.all([getAnyUser(contract, address), getAnyChildren(contract, address)])
      .then(([user, children]) =>
        ({
          ...user,
          address,
          hijos: children.length,
          key: address,
          tags: [user.status, user.guide]
        })
      )
    getAllUsers(contract)
      .then(users => users.slice(1)) // first user is a dummy used for parent calculations
      .then(users => Promise.all( users.map( address => formatUser(address) ) ) ) // add address field, children length and some metadata
      .then(users => {
        if (this._isMounted) {
          this.setState({ dataSource: users, loading: false })
        }
      })
      .catch(err => console.log(err));
  }

  render() {
    const { dashboardStatus } = this.props;
    if (dashboardStatus === 'indicators') {
      return (
        <div>
          <h1>Usuarios</h1>
          <Table {...this.state} columns={columns} />
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardStatus: state.dashBoardStatus,
    contract: state.contractAddress,
  };
};

export default connect(mapStateToProps)(Indicators);