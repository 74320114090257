export const connectWallet = async(chain) => {
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chain.value }],
        });

        if (connect()) return true
        return false
    } catch (error) {
        // This error code indicates that the user rejected the permission.
        if (error.code === 4001) return false
    
        // This error code indicates that the chain has not been added to MetaMask.
        if (error.code === 4902) {
            try {
                console.log('Tratando de agregar la nueva red: ', error);

                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainId: chain.value,
                        chainName: chain.label,
                        rpcUrls: chain.rpcUrls2,
                        blockExplorerUrls: chain.blockExplorerUrls,
                        nativeCurrency: {
                          name: chain.currency,
                          symbol: chain.currency,
                          decimals: chain.decimals,
                        }
                    }],
                });

                if (connect()) return true
                return false
            } catch (addError) {
                console.log('Error: ', addError);
            }
        }
    }
}

const connect = async() => {
    if (window.ethereum) {
        try { 
            await window.ethereum.request({ method: 'eth_requestAccounts' })
            console.log('Acceso concedido...');
            return true
        } catch(e) {
            console.log('Acceso denegado: ', e);
            return false
        }
    }
}
