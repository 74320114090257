import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  getContractBalance,
  getBalance,
  withdrawFunds,
  withdrawSomeFunds,
  withdrawFundsToUser,
  activateUser,
  withdrawAllFunds,
  fromWei,
  getAnyUser,
  utils
} from '../../Web3Wrapper';
import { getEtherPrice } from '../../APIWrapper'
import { Card, message } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import LoadingButton from '../../shared/LoadingButton';
import ConditionalSpin from '../../shared/ConditionalSpin';
import MissingEthers from '../../shared/MissingEthers';
import TransferControl from './TransferControl';
import TransferControlNoBtn from './TransferControlNoBtn';

class Payments extends Component {
  state = {
    ethers: 0,
    dollars: 0,
    //
    allEthers: 0,
    alDollars: 0,
    //
    oUserBalance: '0',
    oUserDollars: '0',
    oUserAddress: '',
    oUserCardShow: false,
    oUserLoaded: false,
    //
    loaded: false,
  }

  _isMounted = false;

  // constructor(props) {
  //   super(props);
  // }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    const { contract } = this.props;
    Promise.all([
      getEtherPrice(this.props.coin),
      getContractBalance(contract),
      getBalance(contract),
    ])
      .then(([price, ethers, allEthers]) => {
        let dollars = price * fromWei(ethers);
        let allDollars = price * fromWei(allEthers);

        if (this._isMounted) {
          this.setState({
            ethers: fromWei(ethers),
            dollars: dollars,
            allEthers: fromWei(allEthers),
            allDollars: allDollars,
            loaded: true,
          })
        }
      })
  }

  checkBalance = () => {
    getAnyUser(this.props.contract, this.state.selectedUser)
      .then(user => user.currentBalance)
      .then(balance => this.setState({ selectedUserBalance: fromWei(balance) }))
  }

  handleGetBalance = (oUserAddress) => {
    return new Promise((resolve, reject) => {
      if (utils.isAddress(oUserAddress)) {
        this.setState({ oUserCardShow: true, oUserLoaded: false },
          () => {
            Promise.all([
              getAnyUser(this.props.contract, oUserAddress),
              getEtherPrice(this.props.coin),
            ])
              .then(([user, price]) => this.setState({
                oUserBalance: fromWei(user.currentBalance),
                oUserDollars: price * fromWei(user.currentBalance),
                oUserLoaded: true
              }, resolve))
              .catch(err => {
                message.error(err.message)
                reject(err)
                this.setState({ oUserCardShow: false, oUserLoaded: false })
              })
          });
      } else {
        message.error("¡Dirección inválida!")
        reject(new Error("¡Dirección inválida!"))
        this.setState({ oUserCardShow: false, oUserLoaded: false })
      }
    })
  }

  handleTransfer = (value) => {
    return new Promise((resolve, reject) => {
      try {
        // let wei_ = utils.toWei(value);
        withdrawFundsToUser(this.props.contract, this.state.oUserAddress, utils.toWei(value))
          .then(resolve)
          .catch(reject)
      }
      catch (e) {
        message.error("Cantidad inválida!")
        reject(e);
      }
    })
  }

  handleActivate = (value) => {
    return new Promise((resolve, reject) => {
      try {
        activateUser(this.props.contract, this.state.oActiveUserAddress, '', this.state.oActiveParentAddress, utils.toWei(value))
          .then(resolve)
          .catch(reject)
      }
      catch (e) {
        message.error("Cantidad inválida!")
        reject(e);
      }
    })
  }

  handleWithdrawSomeFunds = (value) => {
    return new Promise((resolve, reject) => {
      try {
        // let wei_ = utils.toWei(value);
        withdrawSomeFunds(this.props.contract, utils.toWei(value))
          .then(resolve)
          .catch(reject)
      }
      catch (e) {
        message.error("Cantidad inválida!")
        reject(e);
      }
    })
  }

  render() {
    if (this.props.dashboardStatus === 'payments') {
      return (
        <div>
          <Card title="Retirar ethers del contrato">
            <ConditionalSpin showSpin={!this.state.loaded}>
              <MissingEthers isWalletEmpty={this.state.ethers === '0'}>
                <Card>
                  <p> {this.state.ethers} ethers </p>
                  <p> ({this.state.dollars} dólares) </p>
                </Card>
              </MissingEthers>
              <br />
              <LoadingButton
                type="primary"
                ghost onClick={() => withdrawFunds(this.props.contract)}
                disabled={this.state.ethers === '0' ? true : false}
              >
                RECLAMAR FONDOS
              </LoadingButton>
            </ConditionalSpin>
          </Card>
          <br />
          <Card title="Retirar algunos ethers del contrato">
            <ConditionalSpin showSpin={!this.state.loaded}>
              <MissingEthers isWalletEmpty={this.state.ethers === '0'}>
                <Card>
                  <p> {this.state.ethers} ethers </p>
                  <p> ({this.state.dollars} dólares) </p>
                </Card>
              </MissingEthers>
              <br />
              {this.state.ethers === '0' ?
                <LoadingButton
                  type="primary"
                  ghost
                  disabled={true}
                >
                  RECLAMAR FONDOS
                </LoadingButton> :
                <TransferControl
                  addonBefore="Cantidad"
                  placeholder="Aviso: La cantidad se convertirá de ether a wei"
                  onClick={this.handleWithdrawSomeFunds}
                >
                  RECLAMAR FONDOS
                </TransferControl>
              }
            </ConditionalSpin>
          </Card>
          <br />
          <Card title="Retirar TODOS los ethers del contrato">
            <p><WarningTwoTone twoToneColor="#eb2f96" /> Advertencia: la siguiente funcionalidad retira TODOS los ethers del contrato.</p>
            <p>Sin embargo, es posible que algunos ethers pertenezcan a los usuarios por excedente al momento de pagar la tarifa.</p>
            <ConditionalSpin showSpin={!this.state.loaded}>
              <MissingEthers isWalletEmpty={this.state.allEthers === '0'}>
                <Card>
                  <p> {this.state.allEthers} ethers </p>
                  <p> ({this.state.allDollars} dólares) </p>
                </Card>
              </MissingEthers>
              <br />
              <LoadingButton
                type="danger"
                ghost onClick={() => withdrawAllFunds(this.props.contract)}
                disabled={this.state.allEthers === '0' ? true : false} //Desactivado siempre por seguridad, lo normal el else en false
              >
                RECLAMAR TODOS LOS FONDOS
              </LoadingButton>
            </ConditionalSpin>
          </Card>
          <br />
          <Card title="Transferir ethers al usuario">
            {this.state.oUserCardShow ?
              <ConditionalSpin showSpin={!this.state.oUserLoaded}>
                <MissingEthers isWalletEmpty={this.state.oUserBalance === '0'}>
                  <Card>
                    <p>{this.state.oUserBalance} ethers</p>
                    <p>({this.state.oUserDollars} dólares)</p>
                  </Card>
                </MissingEthers>
              </ConditionalSpin>
              : null
            }
            {this.state.oUserCardShow && !this.state.oUserLoaded ? <br /> : null}
            <br />
            <TransferControl
              addonBefore="Dirección"
              placeholder="Ej: 0x3a0ba1e9F35BDE9D1978A6BB9f577436BA7bB54D"
              spammable
              onClick={this.handleGetBalance}
              onChange={(e) => this.setState({ oUserAddress: e })}
            >
              BALANCE
            </TransferControl>
            <br />
            <TransferControl
              addonBefore="Cantidad"
              placeholder="Aviso: La cantidad se convertirá de ether a wei"
              onClick={this.handleTransfer}
            >
              TRANSFERIR
            </TransferControl>
          </Card>
          <br />
          <Card title="Activar un usuario que no se activo automáticamente">
            {this.state.oUserCardShow && !this.state.oUserLoaded ? <br /> : null}
            <br />
            <TransferControlNoBtn
              addonBefore="User address"
              placeholder="Ej: 0x3a0ba1e9F35BDE9D1978A6BB9f577436BA7bB54D"
              onChange={(e) => this.setState({ oActiveUserAddress: e })}
            >
            </TransferControlNoBtn>
            <br />
            <TransferControlNoBtn
              addonBefore="Parent address"
              placeholder="Ej: 0x3a0ba1e9F35BDE9D1978A6BB9f577436BA7bB54D"
              onChange={(e) => this.setState({ oActiveParentAddress: e })}
            >
            </TransferControlNoBtn>
            <br />
            <TransferControl
              addonBefore="Costo de inscripción a cobrar en ETHERs"
              placeholder="Ej: 0.04219"
              onClick={this.handleActivate}
            >
              ACTIVAR USUARIO
            </TransferControl>
          </Card>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardStatus: state.dashBoardStatus,
    contract: state.contractAddress,
    coin: state.selectedChain.currency,
  };
};

export default connect(mapStateToProps)(Payments);