import React from 'react';
import { connect } from 'react-redux';

import { Button } from 'antd';
import Modal from './Modal';

const UserAddedModal = (props) => props.dashboardStatus === 'userAdded' && (
  <Modal>
    <p>Felicidades, te has registrado exitosamente en Qori</p>
    <Button type="primary" onClick={() => props.onChangeDashboard('login')}>
      Aceptar
    </Button>
  </Modal>
);

const mapStateToProps = (state) => ({
  dashboardStatus: state.dashBoardStatus,
});

const mapDispatchToProps = dispatch => ({
  onChangeDashboard: (value) => dispatch({ type: 'CHANGEDASHBOARD', value: value }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAddedModal);