import React from 'react';
import { connect } from 'react-redux'

import Quiz from './Quiz';

const Test = (props) => props.dashBoardStatus === 'test' && (<Quiz />);

const mapStateToProps = (state) => ({
  dashBoardStatus: state.dashBoardStatus,
});

export default connect(mapStateToProps)(Test);