import React, { useState } from 'react';
import { connect } from 'react-redux'
import logo from './../../logo.png';

import { Layout, Menu } from 'antd';

import { TeamOutlined } from '@ant-design/icons';

const getItem = (label, key, icon, children, type, disabled) => {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}

const { Sider } = Layout;

const Sidebar = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [responsivo, setResponsivo] = useState(false);
  const controls = [
    { id: 'home', label: 'INICIO', icon:null, disabled: false },
    { id: 'upgrade', label: 'SUSCRIBIRSE', icon:null, disabled: false },
    { id: 'test', label: 'TEST', icon:null, disabled: false },
    { id: 'affiliates', label: 'AFILIADOS', icon:null, disabled: false },
    { id: 'locations', label: 'UBICACION', icon:null, disabled: false },
    { id: 'separator', label: 'ADMIN', icon: <TeamOutlined />, disabled: true }, // special case
    { id: 'operations', label: 'OPERACIONES', icon:null, disabled: false },
    { id: 'indicators', label: 'USUARIOS', icon:null, disabled: false },
    { id: 'history', label: 'HISTORIAL', icon:null, disabled: false },
    { id: 'payments', label: 'PAGOS', icon:null, disabled: false },
  ];

  const showControl = (user, label) => {
    let status = Number(user.status)
    switch (label) {
      case 'home':
        return true;
      case 'upgrade':
        return (status === 0 || status === 3) ? true : false;
      case 'test':
        return (status === 2 && user.guide === false) ? true : false;
      case 'affiliates':
        return (status === 2 && user.guide === true) ? true : false;
      // only if user does not exists, or is inactive (or always)
      case 'locations':
        return true;
      default:
        return user.admin ? true : false;
    }
  }

  const onCollapse = () => {
    if (responsivo){
      setCollapsed(!collapsed);
    }
  };

  const { user, show, onChangeDashboard } = props;
  if (show === true) {
    let items = [];
    const _controls = controls.map((control, index) => {
      // && (
      //   <Menu.Item
      //     key={index}
      //     onClick={() => {
      //         onChangeDashboard(control.id);
      //         onCollapse();
      //       }
      //     }
      //     style={{ textAlign: "center" }}
      //     disabled={control.id === 'separator'}
      //   >
      //     {control.id === 'separator' && <TeamOutlined />}
      //     <span className="nav-text">{control.label}</span>
      //   </Menu.Item>
      // );
      if(showControl(user, control.id)){
        items.push(
          getItem(control.label, control.id, control.icon, null, null, control.disabled)
        );
      }
    }
    );

    const onClick = (e) => {
      console.log('click ', e);
      onChangeDashboard(e.key);
      onCollapse();
    };
    
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => { 
                                    console.log(broken);
                                    setResponsivo(broken);
                                  }
        }
        onCollapse={() => { onCollapse(); }}
      >
        <div className="logo">
          <img alt="qori-logo" src={logo} />
        </div>
        <Menu
          onClick={onClick}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['home']}
          items={items}
        />
      </Sider>
    )
  }
  return null;
}

const mapStateToProps = state => {
  return {
    user: state.user,
    show: state.showSideBar
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeDashboard: (value) => dispatch({ type: 'CHANGEDASHBOARD', value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);