import React, { Component } from 'react';

import { getPastEvents, getBlock } from '../../Web3Wrapper';
import { convertToDate } from '../../UtilsWrapper';

import { Card } from 'antd';
import ExtendableAddress from '../../shared/ExtendableAddress';
import ConditionalSpin from '../../shared/ConditionalSpin';

class EventHistory extends Component {
  state = {
    logs: [],
    loaded: false,
  }

  _isMounted = false;

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    const { contract, event } = this.props;
    getPastEvents(contract, event)
      .then(events =>
        Promise.all(
          events.map(event => {
            let admin = event.returnValues._admin;
            let value = event.returnValues._value;
            let level = event.returnValues._index;
            let receiver = event.returnValues._receiver;
            let block = event.blockNumber;

            return Promise.all([
              admin,
              value,
              level,
              receiver,
              getBlock(block)
                .then(block => convertToDate(block.timestamp))
            ])
              .then(([admin, value, level, receiver, date]) => ({ admin, value, level, receiver, date }));
          })
        ))
      .then(logs => {
        if (this._isMounted) this.setState({ logs: logs, loaded: true })
      })
      .catch(err => console.log(err));
  }

  render() {
    const { event } = this.props;

    let logs = this.state.logs.map((row, index, array) => (
      <div key={index}>
        <Card>
          <p>Admin: <ExtendableAddress>{row.admin}</ExtendableAddress></p>
          <p>Valor: {row.value}</p>
          {row.level ? (<p>Nivel: {Number(row.level) + 1}</p>) : null}
          {row.receiver ? (<p>Recipiente: <ExtendableAddress>{row.receiver}</ExtendableAddress></p>) : null}
          <p>Fecha: {row.date}</p>
        </Card>
        {array.length - 1 !== index ? <br/> : null}
      </div>
    ))

    return (
      <Card title={event}>
        <ConditionalSpin showSpin={!this.state.loaded}>
          {logs}
        </ConditionalSpin>
      </Card>
    );
  }
}

export default EventHistory;