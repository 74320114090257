import axios from "axios";
import {GET_LOCAL_CHAINID, GET_DEFAULT_CHAIN, GET_ALL_CHAINS} from "./constants";

import { APIEndpoint } from "../utils/APIendpoint";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { deflate } from "zlib";

const getLocalChain = () => {
    return async function (dispatch) {
        const data = window.localStorage.getItem('CHAINID_SELECTED');
        if ( data !== null ) {
            console.log("en setLocalChain de action: Entro al IF, data: " + data);
            return dispatch({
                type: GET_LOCAL_CHAINID,
                payload: JSON.parse(data),
            })
        } else {
            // const data = "0x13881";
            // window.localStorage.setItem('CHAINID_SELECTED', JSON.stringify(data));
            // return dispatch({
            //     type: GET_LOCAL_CHAINID,
            //     payload: JSON.parse(data),
            // })
        }
    }
}

const getAllChains = (chainID) => {
    return async function (dispatch) {
        try {
            const {data} = await axios.get(`${APIEndpoint}chains/getAll`)
            console.log(data);
            if ( chainID !== null ) {
                const chainLocal = data.chains.filter((c)=> c.value === chainID);
                return dispatch({
                    type: GET_ALL_CHAINS,
                    payload: data,
                    contractAddress: chainLocal[0].contractAddress,
                    selectedChain: chainLocal[0],
                })
              } else {
                return dispatch({
                    type: GET_ALL_CHAINS,
                    payload: data,
                    contractAddress: 0x1717,
                })
              }
        } catch (e) {
            console.log(e)
        }
    }
}

const getDefaultChain = () => {
    return async function (dispatch) {
        try {
            const {data} = await axios.get(`${APIEndpoint}chains/getDefault`)
            console.log("getDefaultChain data.value: " + data.value);
            return dispatch({
                type: GET_DEFAULT_CHAIN,
                payload: data.value
            })
        } catch (e) {
            console.log(e)
        }
    }
}

const selectChain = (payload) => {
    /*
    return async function (dispatch) {
        try {
            await axios.post(`${APIEndpoint}chains/setActualChain`, payload)
            return dispatch({
                type: SELECT_CHAIN,
                payload
            })  
        } catch (e) {
            console.log(e)
        }
    }
    */
}

export  {getAllChains, getDefaultChain, getLocalChain}