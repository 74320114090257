import React from 'react';

import Web3Modal from './Web3Modal'
import UnblockModal from './UnblockModal';
import RegisterModal from './RegisterModal'
import UserAddedModal from './UserAddedModal'
import LoginModal from './LoginModal'
import ErrorModal from './ErrorModal';
import SplashScreen from './SplashScreen';
import ChangeChainModal from './ChangeChainModal';

const Modals = () => (
  <div>
    <ChangeChainModal />
    <SplashScreen />
    <Web3Modal />
    <UnblockModal />
    <RegisterModal /> {/*container*/}
    <UserAddedModal />
    <LoginModal /> {/*container*/}
    <ErrorModal />
  </div>
)

export default Modals;