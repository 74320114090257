import React, { Component } from 'react';
import { Button } from 'antd';

class LoadingButton extends Component {
  state = {
    loading: false,
  }

  enterLoading = () => {
    this.setState({ loading: true });
  }

  handleClick = () => {
    this.enterLoading();
    this.props.onClick()
      .then(_ => this.props.spammable ? this.setState({ loading: false }) : _)
      .catch(_ => this.setState({ loading: false }));
  }

  render() {
    return (
      <Button
        {...this.props}
        loading={this.state.loading}
        onClick={this.handleClick}
        spammable={null}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default LoadingButton;