import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd';
import 'antd/dist/reset.css';
import './App.css';

import { getClientAddress, getUser, isOwner, subscribeOnce, utils } from './Web3Wrapper';
import { getUserDB, sendJWT, activateUser } from './APIWrapper';
import { APIEndpoint } from './utils/APIendpoint';

import Header from './containers/Header/Header';
import Sidebar from './containers/Sidebar/Sidebar';
import Dashboard from './containers/Dashboard/Dashboard';

import { getAllChains, getDefaultChain, getLocalChain } from './store/action';

const App = (props) => {
  const { Footer } = Layout;
  let localChainIDStorage = null;
  const chainIDSelectedStorage = window.localStorage.getItem('CHAINID_SELECTED');
  if (chainIDSelectedStorage !== null){
    localChainIDStorage = (JSON.parse(chainIDSelectedStorage));
  } else {
    const chainID = "0x94"; //Ver como se setea de una manera más dinámica, es la red por defecto ahora.
    window.localStorage.setItem('CHAINID_SELECTED', JSON.stringify(chainID));
    localChainIDStorage = chainID;
  }

  const dispatchNew = useDispatch();
  const chainID = localChainIDStorage;
  const allChains = props.allChains;
  const chainDefault = props.chainDefault;
  const contractAddress = props.contractAddress;

  useEffect(() =>{
    dispatchNew(getLocalChain());
  }, [chainID]);

  useEffect(() =>{
    dispatchNew(getAllChains(JSON.parse(window.localStorage.getItem('CHAINID_SELECTED'))));
  }, [allChains]);

  useEffect(() =>{
    dispatchNew(getDefaultChain());
  }, [chainDefault]);

  useEffect(() => {
    const paramArray = window.location.href.split('/');
    const possibleParent = paramArray.pop()
    if (utils.isAddress(possibleParent)) props.onParentChange(possibleParent);
    loadUserData();
  }, []);

  useEffect(() => {
    loadUserData();
  }, [props.contractAddress]);

  const loadUserData = () => {
    Promise.all([
      getClientAddress(),
      getUser(props.contractAddress),
      getUserDB(APIEndpoint),
      isOwner(props.contractAddress)
    ])
      .then(([address, user, userDB, isAdmin]) => {
        let userLoaded = {
          address: address,
          status: user.status,
          email: userDB.email || userDB.email || 'No email found',
          emailProfile: userDB.email || userDB.email || 'No email found',
          parent: user.parent,
          guide: user.guide,
          admin: isAdmin,
          currentBalance: user.currentBalance
        }

        props.onUserChange(userLoaded);

        let isRegistered = Number(user.status) !== 0 || userDB.email;

        if (isRegistered) {
          let jwt = JSON.parse(localStorage.getItem('qoriJWT')); //make qoriJWT a constant

          if (jwt) {
            sendJWT(APIEndpoint, jwt)
              .then(isValid => {
                if (Number(user.status) === 0 && Number(user.currentBalance) > 0) {
                  console.log("Resending petition to activate user in 10...")

                  subscribeOnce(props.contractAddress, 'UserActivation')
                    .then(event => window.location.reload())
                    .catch(err => console.log(err));

                  setTimeout(() => activateUser(APIEndpoint, props.user.address, chainID)
                    .then(res => console.log(res))
                    .catch(err => console.log(err)), 10000)
                }

                props.onChangeDashboard('home');

                props.onToggleHeader(true);
                props.onToggleSidebar(true);
              })
              .catch(err => props.onChangeDashboard('login'));
          } else props.onChangeDashboard('login')
        } else props.onChangeDashboard('register');
      })
      .catch(err => {
        console.log("Entrando al error a ver que pasa...");
        console.log("Valor de parametro a ver, dentro del error: " + contractAddress);
        const invalidProvider = err.message.search('Provider not set or invalid') >= 0;
        const emptyWallet = err.message.search('Metamask wallet is empty!') >= 0;

        if (emptyWallet) props.onChangeDashboard('unblock');
        else if (invalidProvider) props.onChangeDashboard('web3');
        else props.onChangeDashboard('error');
        console.log(err)
      });
  }

  return (
    <div className="App">
      <Layout style={{ minHeight: "100%" }}>
        <Sidebar />
        <Layout >
          <Header style={{ background: '#fff', padding: 0 }} />
          <Dashboard />
          <Footer style={{ textAlign: 'center', paddingBottom: "0px" }}>
            &copy;2023 Qori. All Rights Reserved
          </Footer>
          <Footer style={{ textAlign: 'center', paddingTop: "0px" }}>
            {chainID} - {props.contractAddress}
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log("States del APP:" + JSON.stringify(state));
  return {
    user: state.user,
    contractAddress: state.contractAddress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //onGetLocalChain: () => dispatch(getLocalChain()),
    onChangeDashboard: (value) => dispatch({ type: 'CHANGEDASHBOARD', value: value }),
    onToggleHeader: (value) => dispatch({ type: 'TOGGLEHEADER', value: value }),
    onToggleSidebar: (user) => dispatch({ type: 'TOGGLESIDEBAR', value: user }),
    //
    onUserChange: (user) => dispatch({ type: 'CHANGEUSER', value: user }),
    //
    onParentChange: (id) => dispatch({ type: 'CHANGEPARENT', value: id }),
    //onGetAllChains: (chainID) => dispatch(getAllChains(chainID)),
    //onGetDefaultChain: () => dispatch(getDefaultChain()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
