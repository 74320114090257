import React from 'react';
import { connect } from 'react-redux'

import LocationsMap from '../../components/Maps/LocationsMap';

const Locations = (props) => props.dashboardStatus === 'locations' && (
  <LocationsMap />
);

const mapStateToProps = (state) => ({
  dashboardStatus: state.dashBoardStatus,
});

export default connect(mapStateToProps)(Locations);