import React, { Component } from 'react';
import { connect } from 'react-redux'

import { getEtherPrice, editMail } from '../../APIWrapper'
import { fromWei, /*changeUserEmail*/ } from '../../Web3Wrapper';
import { TeamOutlined, MailOutlined } from '@ant-design/icons';
import { APIEndpoint } from '../../utils/APIendpoint';

import { Card, message } from 'antd';

import GuideMap from '../Maps/GuideMap';
import SharedForm from '../../shared/SharedForm';

const styles = {
  card: {
    padding: "0px",
    marginBottom: "30px",
  }
}

class Profile extends Component {
  state = {
    dollars: '0',
    ethers: '0',
    loading: true,
  }

  componentDidMount = () => {
    getEtherPrice(this.props.coin)
      .then(price => {
        let ethers = fromWei(this.props.user.currentBalance);
        let dollars = price * ethers;
        let cD = parseFloat(dollars.toFixed(5));
        let cE = parseFloat(Number(ethers).toFixed(5));
        this.setState({
          dollars: cD,
          ethers: cE,
          loading: false,
        })
      })
  }

  changeEmail = (val) => {
    let email = val.email;
    let { user, onUserChange, /*contract*/ } = this.props;

    // if (user.status === '0') {
    if (user.status) {
      editMail(APIEndpoint, user.address, email)
        .then(_ => onUserChange({ email: email }))
        .catch(err => console.log(err));
    } // else {
    // changeUserEmail(contract, email)
    //  .then(_ => editMail(APIEndpoint, user.address, email))
    //  .then(_ => onUserChange({ email: email }))
    //  .catch(err => console.log(err));
    //  }
  }

  handleSubmit = (values, validateFields, callback) => {
    validateFields()
      .then(values => {
        callback(values);
        message.success('Actualizado...');
      })
      .catch(err => {
        console.log(err);
        message.success('Sucedio un error. Vuelva a intentarlo.');
      })
  }

  render() {
    const { user, dashboardStatus } = this.props;
    const { ethers, dollars } = this.state;
    if (dashboardStatus === 'profile') {
      return (
        <>
          <Card title="Correo actual" style={styles.card}>
            <SharedForm
              onFinish={(values, validateFields) => this.handleSubmit(values, validateFields, this.changeEmail)}
              addon="E-mail"
              type="email"
              icon={MailOutlined}
              placeholder={user.email}
            />
          </Card>
          <Card title="Guía afiliado" style={styles.card}>
            <SharedForm
              onFinish={(values, validateFields) => this.handleSubmit(values, validateFields, (v) => console.log)}
              addon="Parent"
              type="parent"
              icon={TeamOutlined}
              placeholder={user.parent}
              disabled
            />
          </Card>
          <Card style={styles.card} title="Balance actual" loading={this.state.loading}>
            <p>{ethers} ethers ({dollars} dolares)</p>
          </Card>
          {
            user.guide && (
              <Card style={styles.card} title="Ubicacion" loading={this.state.loading}>
                <GuideMap />
              </Card>)
          }
        </>
      );
    }

    return null;
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    parent: state.userParent,
    dashboardStatus: state.dashBoardStatus,
    contract: state.contractAddress,
    coin: state.selectedChain.currency,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserChange: (user) => dispatch({ type: 'CHANGEUSER', value: user }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);