import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Card, Row, Radio, Form, Checkbox } from 'antd';
import { Button } from 'antd';
import withForm from '../../../shared/HOC/withForm';
import { APIEndpoint } from '../../../utils/APIendpoint';

import './TitleFix.css';

class Quiz extends Component {
  state = {
    quiz: [],
    quizSent: false,
    message: '',
    passed: false,
    accept: false
  }

  componentDidMount = () => {
    fetch(APIEndpoint + "quiz")
      .then(res => res.json())
      .then(res => res.message)
      .then(quiz => this.setState({ quiz }))
      .catch(err => console.log(err))
  }

  handleSubmit = () => {
    this.props.form.validateFields()
      .then(values => this.sendQuiz(values))
      .catch(err => console.log(err))
  }

  sendQuiz = (values) => {
    let answers = []

    Object.entries(values).forEach(e => {
      let [key, value] = e;
      answers.push({ questionIndex: key, answerIndex: value })
    })

    fetch(APIEndpoint + 'quiz', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({
        address: this.props.address,
        answers: answers,
        chain_id: this.props.chainID,
      })
    })
      .then(res => res.json())
      .then(res => {
        let passed = true;

        if (res.message.includes("Fallaste")) {
          passed = false;
        }

        this.setState({ ...res, quizSent: true, passed });
      })
      .catch(err => console.log(err))
  }

  onChangeCheck = (e) => {
    this.setState({
      accept: e
    });
  }

  render() {
    // const { getFieldDecorator } = this.props.form;
    let quiz = this.state.quiz
      .map((q, index) => {
        let { question, answers, questionIndex } = { ...q }
        return (
          <Form.Item key={index}
            name={String(questionIndex)}
            rules={[{ required: true, message: 'Por favor selecciona una respuesta!' }]}>
            <Card
              title={question}
              style={{ marginTop: "30px" }}
            >
              <Radio.Group>
                {answers.map((answer, i) => (
                  <Row style={{ width: "100%" }} key={i}>
                    <Radio value={i} style={{ whiteSpace: "normal" }}>{answer}</Radio>
                  </Row>))}
              </Radio.Group>
            </Card>
          </Form.Item>
        )
      })
    return (
      <Card>
        {!this.state.quizSent &&
          <Form onFinish={this.handleSubmit} form={this.props.form}>
            <p>¡Completa este test para poder volverte un guía y poder afiliar a otros usuarios!</p>
            {quiz}
            <Checkbox onChange={(e) => this.onChangeCheck(e.target.checked)} style={{ float: "right" }}>
              He leído y acepto los <a href="https://qori.io/Guia%20Qori/TyC%20Gui%CC%81as%20Qori%2019-05-07.pdf" target="_blank" rel="noopener noreferrer">términos y condiciones del Guía Qori</a>
            </Checkbox>
            <br />
            <br />
            <Button htmlType="submit" disabled={!this.state.accept} type="primary" style={{ float: "right" }}>Enviar</Button>
          </Form>
        }
        {this.state.quizSent && <p>{this.state.message}</p>}
        {this.state.quizSent && this.state.passed &&
          <span>
            <p>En un par de minutos refresca la página para reconocer tu nuevo status de Guía Qori!</p>
            <p>Y mientras esperas por favor lee con detenimiento los siguientes Términos y Condiciones:</p>
            <p><a href="https://qori.io/Guia%20Qori/TyC%20Generales%20Qori%2019-05-07.pdf" target="_blank" rel="noopener noreferrer">Link a los
            términos y condiciones generales de usuarios</a></p>
            <p>Descarga aquí tu manual del Guía Qori (Al descargar tu manual, automáticamente estás aceptando los
            Términos y Condiciones del Guía Qori):</p>
            <p><a href="https://qori.io/Guia%20Qori/Manual%20del%20Guia%20Qori.pdf" target="_blank" rel="noopener noreferrer">Link al manual del
            Guía Qori</a></p>
            <p><a href="https://qori.io/Guia%20Qori/TyC%20Gui%CC%81as%20Qori%2019-05-07.pdf" target="_blank" rel="noopener noreferrer">Link a los
            términos y condiciones del Guía Qori</a></p>
          </span>}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  address: state.user.address,
  chainID: state.chainID,
});

// export default connect(mapStateToProps)(Form.create({ name: 'validate_other' })(Quiz));
export default connect(mapStateToProps)(withForm(Quiz));