import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import Modal from './Modal';

const Web3Modal = (props) => props.dashboardStatus === 'web3' && (
  <Modal>
    <p>¡Metamask no está instalado!</p>
    <Button type="primary" href="https://metamask.io/download/" target="_blank">Descárgalo aquí</Button>

    <p>Una vez que lo hayas instalado, haz click <p onClick={() => window.location.reload()}>aquí</p> para comenzar</p>
  </Modal>
);

const mapStateToProps = (state) => ({
  dashboardStatus: state.dashBoardStatus,
});

export default connect(mapStateToProps)(Web3Modal);