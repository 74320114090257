import React from 'react';

import { Form, Input, Button } from 'antd';
import withForm from './HOC/withForm';

const styles = {
  transparent: {
    color: 'rgba(0,0,0,.25)'
  }
}

const SharedForm = (props) => (
  <Form onFinish={(values) => props.onFinish(values, props.form.validateFields)} form={props.form} className="login-form">
    <Form.Item
      name={[props.type]}
      rules={[{ required: true, message: 'Please input your ' + props.type + '!' }]} >
      <Input
        addonBefore={props.addon}
        disabled={props.disabled}
        prefix={<props.icon style={styles.transparent} />}
        placeholder={props.placeholder} />
    </Form.Item>
    <Form.Item>
      <Button disabled={props.disabled} type="primary" className="login-form-button" htmlType="submit">
        Actualizar
      </Button>
    </Form.Item>
  </Form >)

// export default Form.useForm({ name: 'normal_login' })(SharedForm);
export default withForm(SharedForm);