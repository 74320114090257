import React, { Component } from 'react';
import { connect } from 'react-redux'

import { getGuideMarkers } from '../../APIWrapper';
import LocationsMapComponent from './LocationsMapComponent';
import { Card, Spin } from 'antd';
import { APIEndpoint } from '../../utils/APIendpoint';

class LocationsMap extends Component {
  state = {
    coordinatesLoaded: false,
    // Centro de Lima
    lat: -12.0464,
    lng: -77.0428,
    info: false,
    markers: [],
  }

  _isMounted = false;

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    getGuideMarkers(APIEndpoint)
      .then((markers) => {
        if (this._isMounted) {
          this.setState({
            coordinatesLoaded: true,
            markers: markers,
          })
        }
      })
      .catch(err => console.log(err));
  }

  render() {
    const { coordinatesLoaded, lat, lng, markers } = this.state

    return <>
      <p>Si eres un guía, puedes guardar tu ubicación en tu perfil (ícono de la persona arriba a la derecha).</p>
      {coordinatesLoaded ? (

        <LocationsMapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCNBeqrIDyadJg9l9Fj_GyzprfMfq7WKr0&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          lat={lat}
          lng={lng}
          markers={markers}
        />
      ) : (
          <Card>
            Cargando las ubicaciones de los Guias Qori...
            <br /><br />
            <Spin />
          </Card>
        )}
    </>
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserChange: (user) => dispatch({ type: 'CHANGEUSER', value: user })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsMap);