import React, { Component } from 'react';

import { Input } from 'antd';
import LoadingButton from '../../shared/LoadingButton';

const InputGroup = Input.Group;

class TransferControl extends Component {
  state = { input: '' }

  handleChange = (e) => {
    const { props } = this;
    this.setState({ input: e.target.value })
    if (props.onChange) props.onChange(e.target.value)
  }

  render() {
    const { props, state } = this;
    return (
      <InputGroup compact>
        <Input
          addonBefore={props.addonBefore}
          placeholder={props.placeholder}
          onChange={(e) => this.handleChange(e)}
          style={{ width: '80%' }}
        />
        <LoadingButton
          onClick={() => props.onClick(state.input)}
          style={{ width: '20%' }}
          spammable={props.spammable}
        >
          {props.children}
        </LoadingButton>
      </InputGroup>
    );
  }
}

export default TransferControl;