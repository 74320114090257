import  {GET_LOCAL_CHAINID, GET_DEFAULT_CHAIN, GET_ALL_CHAINS}  from "./constants";

const initialState = {

  user: {
    address: "0x0000000000000000000000000000000000000000",
    status: 0,
    email: "-",
    emailProfile: "-",
    parent: "0x0000000000000000000000000000000000000000",
    guide: false,
    place: "",
    admin: false,
    currentBalance: '0',
  },

  chainID: null,

  dashBoardStatus: 'splash',

  showDashBoard: true,
  showHeader: false,
  showSideBar: false,

  children: [],

  etherPrice: 0,
  selectedChain: {},
  chainDefault: [],
  allChains : [],
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_LOCAL_CHAINID:
      return {
        ...state,
        chainID: action.payload
      }
    case 'CHANGEPARENT':
      return {
        ...state,
        userParent: action.value
      }
    /**********
     * Containers
     */
    case 'TOGGLEHEADER':
      return {
        ...state,
        showHeader: action.value,
      }
    case 'TOGGLESIDEBAR':
      return {
        ...state,
        showSideBar: action.value,
      }
    case 'TOGGLEDASHBOARD':
      return {
        ...state,
        showDashBoard: action.value,
      }
    /*********
     * User Data
     */
    case 'CHANGEUSER':
      return {
        ...state,
        user: { ...state.user, ...action.value }
      }
    case 'CHANGECHILDREN':
      return {
        ...state,
        children: action.value
      }
    /************
     * Dashboard
     */
    case 'CHANGEDASHBOARD':
      return {
        ...state,
        dashBoardStatus: action.value
      }
    // case SELECT_CHAIN:
    //   return {
    //     ...state,
    //     selectedChain: action.payload,
    //     contractAddress: action.payload.contractAddress,
    //     chainID: action.payload.value,
    //   }
    case GET_ALL_CHAINS:
      return {
        ...state,
        allChains: action.payload.chains,
        contractAddress: action.contractAddress,
        selectedChain: action.selectedChain,
      }
    case GET_DEFAULT_CHAIN:
      let current = state.allChains.filter((c)=> c.value === action.payload);
      return {
        ...state,
        chainDefault: current,
      }
    default: {
      return state;
    }
  }
};

export default reducer;