import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, TeamOutlined, MailOutlined } from '@ant-design/icons';
import withForm from '../shared/HOC/withForm';
import { APIEndpoint } from '../utils/APIendpoint';

import { registerUserDB } from './../APIWrapper';
import Modal from './Modal';

const styles = {
  transparent: {
    color: 'rgba(0,0,0,.25)'
  }
}

class RegisterModal extends Component {

  state = {
    accept: false,
    loading: false
  }

  registerUser = (values) => {
    let user = {
      email: values.email,
      address: this.props.user.address,
      user_parent: this.props.parent
    }
    registerUserDB(APIEndpoint, user, this.props.chainID)
      .then(data => {
        if (data.err) throw new Error(data.err);
        if (data.message && data.message.errno) throw new Error("Connection refused");
        this.setState({
          loading: false
        });
        this.props.onUserChange({ email: values.email, emailProfile: values.email });
        this.props.onChangeDashboard('userAdded');
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false
        });
        this.props.onChangeDashboard('error')
      })
  }

  handleSubmit = (values) => {
    this.setState({
      loading: true
    });
    this.props.form.validateFields()
      .then(values => this.registerUser(values))
      .catch(err => {
        console.log(err)
        this.setState({
          loading: false
        });
      });
  }

  onChangeCheck = (e) => {
    this.setState({
      accept: e
    });
  }

  render() {
    return this.props.dashboardStatus === 'register' && (
      <Modal>
        <Form form={this.props.form} onFinish={this.handleSubmit} className="login-form">
          <Form.Item>
            <Input disabled
              prefix={<UserOutlined style={styles.transparent} />}
              placeholder="Username"
              value={this.props.user.address} />
          </Form.Item>
          {this.props.parent &&
            <Form.Item>
              <Input disabled
                prefix={<TeamOutlined style={styles.transparent} />}
                placeholder="Parent"
                value={this.props.parent} />
            </Form.Item>
          }
          <Form.Item
            name={['email']}
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input
              prefix={<MailOutlined style={styles.transparent} />}
              placeholder="E-mail"
              onChange={this.handleEmailChange} />
          </Form.Item>
          <Form.Item>
            <p>Por favor, introduce tu dirección de e-mail y presiona "Continuar"</p>
            <Checkbox onChange={(e) => this.onChangeCheck(e.target.checked)}>
              He leído y acepto los <a href="https://qori.io/Guia%20Qori/TyC%20Generales%20Qori%2019-05-07.pdf" target="_blank" rel="noopener noreferrer">términos y condiciones.</a>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              disabled={!this.state.accept}
              loading={this.state.loading}
              className="login-form-button"
              htmlType="submit">
              CONTINUAR
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  parent: state.userParent,
  //
  dashboardStatus: state.dashBoardStatus,
  chainID: state.chainID,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeDashboard: (value) => dispatch({ type: 'CHANGEDASHBOARD', value: value }),
  //
  onUserChange: (user) => dispatch({ type: 'CHANGEUSER', value: user }),
});

// export default Form.create({ name: 'normal_login' })(connect(mapStateToProps, mapDispatchToProps)(RegisterModal));
export default connect(mapStateToProps, mapDispatchToProps)(withForm(RegisterModal));